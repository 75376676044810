import React from 'react';
import ZipSearch from '../ZipSearch/ZipSearch';
import CountiesSearch from '../CountiesSearch/CountiesSearch';
import ServicesOffered from '../ServicesOffered/ServicesOffered';
import './FilterColumn.css'
import ProjectTypes from '../ProjectTypes/ProjectTypes';
import ProgramTypes from '../ProgramTypes/ProgramTypes';
import LanguageFilter from '../LanguageFilter/LanguageFilter';
import NameSearch from '../NameSearch/NameSearch';


// const FilterColumn = ({handleZipSearch, counties, handleCountyChange, selectedCounty, services, handleServiceChange, 
//     selectedService, filterCommercial, setFilterCommercial, filterResidential, setFilterResidential, filterBoth, setFilterBoth,
//     filterCCEFResidential, setFilterCCEFResidential, filterCCEFCommercial, setFilterCCEFCommercial, filterCEOTaxCredits, setFilterCEOTaxCredits,
//     filterEnglish, setFilterEnglish, filterSpanish, setFilterSpanish, filterOther, setFilterOther, resetFilters, zipCode, radius, setZipCode, setRadius, applyFilters, 
//     companyNames, handleCompanySelection, searchTerm, setSearchTerm
// }) => {

    const FilterColumn = ({handleZipSearch, counties, handleCountyChange, selectedCounty, services, handleServiceChange, 
        selectedService, filterCommercial, setFilterCommercial, filterResidential, setFilterResidential, filterBoth, setFilterBoth,
        filterCCEFRenu, setFilterCCEFRenu, filterCCEFOnBill, setFilterCCEFOnBill, filterCCEFCommercial, setFilterCCEFCommercial, filterCEOTaxCredits, setFilterCEOTaxCredits,
        filterEnglish, setFilterEnglish, filterSpanish, setFilterSpanish, filterOther, setFilterOther, resetFilters, zipCode, radius, setZipCode, setRadius, applyFilters, 
        companyNames, handleCompanySelection, searchTerm, setSearchTerm
    }) => {
   
        return (
        <div className="filter-column">
            <p><strong>Filters</strong></p>
            <hr />
            <ZipSearch onSearch={handleZipSearch} zipCode={zipCode} radius={radius} setZipCode={setZipCode} setRadius={setRadius}/>
            <hr />
            <NameSearch companyNames={companyNames} handleCompanySelection={handleCompanySelection} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <hr />
            <CountiesSearch counties={counties} handleCountyChange={handleCountyChange} selectedCounty={selectedCounty}/>
            <hr />
            {/* <ProgramTypes filterCCEFResidential={filterCCEFResidential} setFilterCCEFResidential={setFilterCCEFResidential} filterCCEFCommercial={filterCCEFCommercial} setFilterCCEFCommercial={setFilterCCEFCommercial} filterCEOTaxCredits={filterCEOTaxCredits} setFilterCEOTaxCredits={setFilterCEOTaxCredits} />
             */}
             <ProgramTypes filterCCEFRenu={filterCCEFRenu} setFilterCCEFRenu={setFilterCCEFRenu} filterCCEFOnBill={filterCCEFOnBill} setFilterCCEFOnBill={setFilterCCEFOnBill} filterCCEFCommercial={filterCCEFCommercial} setFilterCCEFCommercial={setFilterCCEFCommercial} filterCEOTaxCredits={filterCEOTaxCredits} setFilterCEOTaxCredits={setFilterCEOTaxCredits} />
            <hr />
            <ProjectTypes filterCommercial={filterCommercial} setFilterCommercial={setFilterCommercial} filterResidential={filterResidential} setFilterResidential={setFilterResidential} filterBoth={filterBoth} setFilterBoth={setFilterBoth} />
            <hr />
            <ServicesOffered services={services} handleServiceChange={handleServiceChange} selectedService={selectedService} />
            <hr />
            <LanguageFilter filterEnglish={filterEnglish} setFilterEnglish={setFilterEnglish} filterSpanish={filterSpanish} setFilterSpanish={setFilterSpanish} filterOther={filterOther} setFilterOther={setFilterOther} />
            <hr />
            <div className='reset-btn-div'>
                <button onClick={applyFilters} className="apply-btn green-bg white-txt">Apply Filters</button>
            </div>
            <div className='reset-btn-div'>
                <button onClick={resetFilters} className="reset-btn">Reset Filters</button>
            </div>
        </div>
    );
};

export default FilterColumn;