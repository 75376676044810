import React from 'react';
import './ProgramTypes.css'

// const ProgramTypes = ({filterCCEFResidential, setFilterCCEFResidential, filterCCEFCommercial, setFilterCCEFCommercial, filterCEOTaxCredits, setFilterCEOTaxCredits}) => {
const ProgramTypes = ({filterCCEFRenu, setFilterCCEFRenu, filterCCEFOnBill, setFilterCCEFOnBill, filterCCEFCommercial, setFilterCCEFCommercial, filterCEOTaxCredits, setFilterCEOTaxCredits}) => {

    return (
    <div className="program-checkboxes">
        <p>Program Types:</p>
        <label>
            <input
                type="checkbox"
                checked={filterCCEFOnBill}
                onChange={() => setFilterCCEFOnBill(!filterCCEFOnBill)}
            /> CCEF Residential Program - On-Bill Repayment
        </label>
        <label>
            <input
                type="checkbox"
                checked={filterCCEFRenu}
                onChange={() => setFilterCCEFRenu(!filterCCEFRenu)}
            /> CCEF Residential Program - Renu
        </label>
        <label>
            <input
                type="checkbox"
                checked={filterCCEFCommercial}
                onChange={() => setFilterCCEFCommercial(!filterCCEFCommercial)}
            /> CCEF Commercial Program
        </label>
        <label>
            <input
                type="checkbox"
                checked={filterCEOTaxCredits}
                onChange={() => setFilterCEOTaxCredits(!filterCEOTaxCredits)}
            /> CEO Heat Pump Tax Credits Program
        </label>
    </div>
    );
};

export default ProgramTypes;