import React from 'react';
import './ZipSearch.css'

const ZipSearch = ({ onSearch, zipCode, radius, setZipCode, setRadius }) => {

  const handleSearch = () => {
    if (!zipCode || !radius) {
      alert('Please enter both a ZIP code and radius.');
      return;
    }

    // Call the onSearch callback with zipCode and radius
    onSearch(zipCode, radius);
  };

  return (
    <div className="zip-search">
      <p>Search zip code</p>
      <input
        type="text"
        placeholder="Enter zip"
        value={zipCode}
        className='zip-input'
        onChange={(e) => setZipCode(e.target.value)}
      />
       <select
        value={radius}
        className='zip-miles'
        onChange={(e) => setRadius(e.target.value)}
      >
        <option value="5">5 miles</option>
        <option value="10">10 miles</option>
        <option value="25">25 miles</option>
        <option value="50">50 miles</option>
        <option value="100">100 miles</option>
      </select>
      <button onClick={handleSearch} className='zip-btn green-bg white-txt'>Go</button>
    </div>
  );
};

export default ZipSearch;